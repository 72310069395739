.selected-link {
    font-weight: bold;
}
.navBar {
    background-color: #f2f2f2 !important;
    margin-bottom: 50px;
}

.rounded-bar {
    border-radius: 10px;
}