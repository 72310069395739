.text {
    word-wrap: break-word;
    font-family: "Roboto";
}

.contoured {
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
}

.job-background-even {
    background-color: #f2f2f2 !important;
}
  
.job-background-odd {
    background-color: #ffffff !important;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 576px) {
.text {
    font-size: 14px !important;
}
}

@media (min-width: 577px) and (max-width: 768px) {
.text {
    font-size: 16px !important;
}
}

@media (min-width: 769px) and (max-width: 992px) {
.text {
    font-size: 18px !important;
}
}

@media (min-width: 993px) {
.text {
    font-size: 20px !important;
}
}