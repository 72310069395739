.intro-text {
    word-wrap: break-word;
    text-align: justify;
    font-family: "Roboto";
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
}

.introContainer {
    background: linear-gradient(to bottom, #f2f2f2, #d9d9d9);
}

.jsonInfo {
    background: linear-gradient(to top, #f2f2f2, #d9d9d9)
}

.rounded {
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
}