.job-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-family: "Roboto";
}

.text {
    word-wrap: break-word;
    font-family: "Roboto";
}

.text-time-of-tenure {
    text-align: right;
}

.text-title {
    font-weight: bold;
    text-align: center;
}

.job-background-even {
    background-color: #f2f2f2 !important;
}
  
.job-background-odd {
    background-color: #ffffff !important;
}

.root-row {
    margin-top: "200px";
    margin-bottom: "200px";
}

.bullet-point {
    margin-left: 2em;
}

.job-description {
    text-align: justify
}